.blank {
    position: absolute;
    visibility: hidden;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: 0;
  }
  
  #imgPopupWrap::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: black;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: 0.6;
  }
  
  .product-container {
    width: 90%;
    /* margin: auto; */
    /* top: 50%;
    left: 50%; */
    position: relative;
    /* transform: translate(-50%); */
    background: #3c3c3c;
    padding: 20px;
    margin: auto;
    
  }
  .closeBtn {
    position: absolute;
    right: 30px;
    top: 8%;
    padding: 15px;
    background: white;
    line-height: 0;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .bgshadow {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
  }
  