.container-cart {
  display: flex;
  padding: 25px 50px 0 50px;
}
.pri {
  color: #000000;
}

.container-cart h2 {
  margin: auto;
  justify-items: center !important;
  font-size: 15px !important;
}
.container-cart h3 {
  margin: auto;
  justify-items: center !important;
  font-size: 16px !important;
}
.container-cart h3 span {
  height: 30px;
  width: 30px;
  padding: 10px;
  cursor: pointer;
  background-color: #e0e0e0;
}
/* .container h3 span:hover {
  color: white;
  background-color: rgb(218, 88, 41);
} */

/* .container h3 .qty {
  padding: 0 10px;
  background-color: #2a303c !important;
} */

.cart__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  margin-top: 50px;
}

.container-cart .plus:hover,
.container-cart .minus:hover {
  color: #000000 !important;
  border: 1px solid #8cddb4 !important;
}
.total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 50px;
  margin-top: 10px;

  font-size: 20px;
  font-weight: 600;
}

@media only screen and (max-width: 639px) {
  .container-cart {
    padding: 0 0 0 0;
  }
  .container-cart h2 {
    justify-items: center !important;
    font-size: 12px !important;
  }
  .container-cart h3 {
    /* justify-items: center !important; */
    font-size: 12px !important;
    margin-left: -15px;
  }
  .container-cart h3 span {
    height: 20px;
    width: 15px;
    /* padding: 10px; */
    cursor: pointer;
    background-color: #e0e0e0;
  }
  .pri {
    margin-left: 15px;
  }
}
